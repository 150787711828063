/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var myMixin = {
    methods: {
      isEmpty: function(item) {
        return _.isEmpty(item);
      },
      openOverlayPhilanthropy: function (projectId) {
        if( !$('body').hasClass('is-overlay') ) {
          $('body').addClass('is-overlay');
          this.updateProject(projectId);
        }
      },
      closeOverlayPhilanthropy: function () {
        if( $('body').hasClass('is-overlay') ) {
          $('body').removeClass('is-overlay');

          if ( $('.overlay-project-photos-list').hasClass('slick-initialized') ) {
            $('.overlay-project-photos-list').slick('unslick');
          }
        }
      },
      openOverlayProject: function (catId, projectId) {
        this.catId = catId;
        this.category = this.categories[this.catId];

        if( !$('body').hasClass('is-overlay') ) {
          $('body').addClass('is-overlay');
          this.updateProject(projectId);
        }
      },
      closeOverlayProject: function () {
        if( $('body').hasClass('is-overlay') ) {
          $('body').removeClass('is-overlay');

          if ( $('.overlay-project-photos-list').hasClass('slick-initialized') ) {
            $('.overlay-project-photos-list').slick('unslick');
          }
        }
      }
    }
  };

  var setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires='+d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  var getCookie = function (cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var body = $('body');

        var rafScroll = function (cb) {
          // Avoid calculations if not needed
          var lastPosition = -1;
          var render = function() {
              if (lastPosition === window.pageYOffset) {
                  return false;
              } else {
                  lastPosition = window.pageYOffset;
              }

              cb();
          };
          var draw = function() {
              requestAnimationFrame(draw);
              render();
          };

          // https://gist.github.com/paulirish/1579671
          var lastTime = 0;
          var vendors = ['ms', 'moz', 'webkit', 'o'];

          for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
              window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
              window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
          }

          if (!window.requestAnimationFrame) {
              window.requestAnimationFrame = function(callback, element) {
                  var currTime = new Date().getTime();
                  var timeToCall = Math.max(0, 16 - (currTime - lastTime));
                  var id = window.setTimeout(function() { callback(currTime + timeToCall); },
                    timeToCall);
                  lastTime = currTime + timeToCall;
                  return id;
              };
          }

          if (!window.cancelAnimationFrame) {
              window.cancelAnimationFrame = function(id) {
                  clearTimeout(id);
              };
          }

          draw();
        };

        rafScroll( function() {
          var st = $(window).scrollTop();

          if ($('.contact-us').length > 0 || $('.single-post').length > 0 || $('.single-project').length > 0) {
            body.addClass('is-topnavbar-active');
          } else {
            if ( st < 60 ) {
              if ( body.hasClass('is-topnavbar-active') ) {
                body.removeClass('is-topnavbar-active');
              }
            } else {
              if ( !body.hasClass('is-topnavbar-active') ) {
                body.addClass('is-topnavbar-active');
              }
            }
          }
        });

        var navOverlay = $('.nav-overlay');
        $('.hamburger').on('click', function () {
          var el = $(this);
          if (body.hasClass('is-hamburger-active')) {
            body.removeClass('is-hamburger-active');
            el.removeClass('is-active');
          } else {
            body.addClass('is-hamburger-active');
            el.addClass('is-active');
          }
        });

        $('.backtotop').on('click', function () {
          TweenMax.to( window, 1.3, { scrollTo: { y: 0 }, ease:Expo.easeInOut });
        });

        $('.scroll').on('click', function () {
          var top = 0;
          var mostBottomTop = $(document).height() - $(window).height();
          if ($('.home').length > 0) {
            top = $(window).outerHeight();

            if ( top < mostBottomTop ) {
              TweenMax.to(window,1.3,{scrollTo:{y:top},ease:Expo.easeInOut});
            } else {
              TweenMax.to(window,1.3,{scrollTo:{y:mostBottomTop},ease:Expo.easeInOut});
            }
          } else {
            top = $(window).outerHeight() - $('.topnavbar').outerHeight();

            if ( top < mostBottomTop ) {
              TweenMax.to(window,1.3,{scrollTo:{y:top},ease:Expo.easeInOut});
            } else {
              TweenMax.to(window,1.3,{scrollTo:{y:mostBottomTop},ease:Expo.easeInOut});
            }
          }
        });

        if($('#app').length > 0) {
          $(document).keyup(function(event) {
            if (event.keyCode === 27) {
              myMixin.methods.closeOverlayProject();
            }
          });
        }

        $(window).on('load', function () {
          $('body').addClass('is-loaded');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var disableSplash = function () {
          $('body').css('overflow', 'auto');
          if (!$('.home-banner').hasClass('is-disabled')) {
            $('.home-banner').addClass('is-disabled');
          }
        };

        if (getCookie('isApricotSplash') !== '') {
          disableSplash();
        }

        $('.home-banner-arrow').on('click', function () {
          disableSplash();
          setCookie('isApricotSplash', false, (1/24) / 4); // 15min
        });

        $(window).load(function() {
          setTimeout(function() {
            $(window).scrollTop(0);
          }, 0);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'philanthropy': {
      init: function() {

        var app = new Vue({
          el: '#app',
          mixins: [myMixin],
          data: {
            projectId: 0,
            photos: []
          },
          methods: {
            isEmpty: function(item) {
              return _.isEmpty(item);
            },
            updateProject: function (projectId) {
              var id = projectId;
              if (projectId > this.photos.length - 1) {
                id = 0;
              }
              if (projectId < 0) {
                id = this.photos.length - 1;
              }

              this.projectId = id;

              if ( $('.overlay-project-photos-list').hasClass('slick-initialized') ) {
                $('.overlay-project-photos-list').slick('unslick');
              }

              this.$nextTick(function() {
                $('.overlay-project-photos-list').slick({
                  initialSlide: this.projectId,
                  rows: 0,
                  arrows: true,
                  nextArrow: '<svg class="svg-chervon svg-chervon-next" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.711 37.404"><g transform="translate(-1801.988 -1310.598)"><path d="M0,0,16.423,17.2,0,33.926" transform="translate(1803.797 1312.324)"/></g></svg>',
                  prevArrow: '<svg class="svg-chervon svg-chervon-prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.711 37.404"><g transform="translate(1823.699 1348.002) rotate(180)"><path d="M0,0,16.423,17.2,0,33.926" transform="translate(1803.797 1312.324)"/></g></svg>'
                });
              });
            }
          },
          computed: {
            overlayPhotos: function () {
              return this.photos;
            }
          },
          mounted: function () {
            this.photos = JSON.parse($('#app').attr('data-photos'));
          }
        });

      },
      finalize: function() {
      }
    },
    'portfolio': {
      init: function() {

        var app = new Vue({
          el: '#app',
          mixins: [myMixin],
          data: {
            catId: 0,
            projectId: 0,
            categories: [],
            category: []
          },
          methods: {
            updateCatId: function (catId) {
              this.catId = catId;
              this.category = this.categories[this.catId];
            },
            updateProject: function (projectId) {
              var id = projectId;
              if (projectId > this.category.projects.length - 1) {
                id = 0;
              }
              if (projectId < 0) {
                id = this.category.projects.length - 1;
              }

              this.projectId = id;

              if ( $('.overlay-project-photos-list').hasClass('slick-initialized') ) {
                $('.overlay-project-photos-list').slick('unslick');
              }

              this.$nextTick(function() {
                $('.overlay-project-photos-list').slick({
                  rows: 0,
                  arrows: true,
                  nextArrow: '<svg class="svg-chervon svg-chervon-next" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.711 37.404"><g transform="translate(-1801.988 -1310.598)"><path d="M0,0,16.423,17.2,0,33.926" transform="translate(1803.797 1312.324)"/></g></svg>',
                  prevArrow: '<svg class="svg-chervon svg-chervon-prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.711 37.404"><g transform="translate(1823.699 1348.002) rotate(180)"><path d="M0,0,16.423,17.2,0,33.926" transform="translate(1803.797 1312.324)"/></g></svg>'
                });
              });
            }
          },
          computed: {
            overlayPhotos: function () {
              return this.category.projects[this.projectId].photos;
            }
          },
          mounted: function () {
            this.categories = JSON.parse($('#app').attr('data-categories'));
            this.category = this.categories[this.catId];
          }
        });

      },
      finalize: function() {
      }
    },
    'contact_us': {
      init: function() {
        // map array of styles
        var styles = [
            {
                "featureType": "poi",
                "stylers": [
                    { "visibility": "off" }
                ]
            },{
                "featureType": "landscape",
                "stylers": [
                    { "visibility": "off" }
                ]
            },{
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    { "color": "#cccccc" },
                    { "weight": 1 }
                ]
            },{
                "featureType": "landscape.natural",
                "elementType": "geometry.fill",
                "stylers": [
                    { "color": "#ffffff" },
                    { "visibility": "on" }
                ]
            },{
                "featureType": "water",
                "stylers": [
                    { "color": "#aaaaaa" }
                ]
            },{

            }
        ];
        var styledMap;
        var map;
        var marker;
        var lat = 1.2774507;
        var lng = 103.7963695;
        var initMap = function () {
          styledMap = new google.maps.StyledMapType( styles, {name: 'Styled Map'} );
          map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: lat, lng: lng},
            zoom: 17,
            scrollwheel: false
          });

          var imageMarkerUrl = $('#settings').attr('data-template-directory') + '/dist/images/common/logo.svg';
          var imageMarker = {
            url: imageMarkerUrl,
            scaledSize: new google.maps.Size(40, 40),
            size: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 20)
          };
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: imageMarker,
            map: map
          });

          map.mapTypes.set('map_style', styledMap);
          map.setMapTypeId('map_style');
        };
        $(window).load( function() {
          initMap();
        });
      },
      finalize: function() {
      }
    },
    'playspace': {
      init: function() {
        // manual listen on bullets as it is outside of default workings of carousel
        $('#carousel-space').on('slide.bs.carousel', function (event) {
          $('li[data-target="#carousel-space"]').removeClass('active');
          $('li[data-target="#carousel-space"]').eq(event.to).addClass('active');
        });
      },
      finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
